import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Store } from 'Store';
import { Table } from 'react-bootstrap';

const BrandsInfoScreen = () => {
  const [brands, setBrands] = useState([]); // Liste aller Marken
  const [editingIndex, setEditingIndex] = useState(null); // Index der aktuell bearbeiteten Marke

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const { data } = await axios.get('/api/brands', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setBrands(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchBrands();
  }, []);

  const handleInputChange = (e, index, field, subField = null) => {
    const { value } = e.target;
    setBrands((prev) =>
      prev.map((brand, i) => {
        if (i !== index) return brand;

        if (subField) {
          return {
            ...brand,
            [field]: {
              ...brand[field],
              [subField]: value,
            },
          };
        }

        return { ...brand, [field]: value };
      })
    );
  };

  const handleSave = async (index) => {
    const brand = brands[index];
    try {
      const { data } = await axios.post('/api/brands', brand, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setBrands((prev) =>
        prev.map((b, i) => (i === index ? { ...data, rating: b.rating } : b))
      );
      setEditingIndex(null);
    } catch (err) {
      console.error(err);
    }
  };

  const renderInput = (index, field, subField = null) => {
    const value = subField
      ? brands[index][field][subField]
      : brands[index][field];
    return (
      <input
        type="text"
        value={value || ''}
        placeholder={
          field === 'address' || field === 'addressEurope'
            ? subField === 'city'
              ? 'Straße'
              : subField === 'postalcode'
              ? 'PLZ'
              : subField === 'street'
              ? 'Straße + Hausnummer'
              : ''
            : ''
        }
        onChange={(e) => handleInputChange(e, index, field, subField)}
      />
    );
  };

  return (
    <div className="px-1">
      <h1 className="mb-4">Marken Information</h1>
      <div className="w-100 d-flex justify-content-center">
        <Table
          striped
          bordered
          hover
          responsive
          style={{
            fontSize: '0.75rem',
            overflow: 'auto',
            height: '75vh',
            display: 'block',
          }}
          className="w-100"
        >
          <thead className="table-dark">
            <tr>
              <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>Marke</th>
              <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>Email</th>
              <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>Adresse</th>
              <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                Aus Europe?
              </th>
              <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                Adresse (Europe)
              </th>
              <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                Email (Europe)
              </th>
              <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand, index) => (
              <tr key={brand.brand}>
                <td>{brand.brand}</td>
                <td>
                  {editingIndex === index
                    ? renderInput(index, 'email')
                    : brand.email}
                </td>
                <td>
                  {editingIndex === index ? (
                    <>
                      {renderInput(index, 'address', 'street')}
                      <br />
                      {renderInput(index, 'address', 'postalcode')}
                      <br />
                      {renderInput(index, 'address', 'city')}
                    </>
                  ) : (
                    `${brand.address?.street || ''}, ${
                      brand.address?.postalcode || ''
                    } ${brand.address?.city || ''}`
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <select
                      className="form-select"
                      value={brand.isEurope}
                      style={{ fontSize: '0.75rem' }}
                      onChange={(e) => handleInputChange(e, index, 'isEurope')}
                    >
                      <option value={true}>Ja</option>
                      <option value={false}>Nein</option>
                    </select>
                  ) : brand.isEurope ? (
                    'Ja'
                  ) : (
                    'Nein'
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <>
                      {renderInput(index, 'addressEurope', 'street')}
                      <br />
                      {renderInput(index, 'addressEurope', 'postalcode')}
                      <br />
                      {renderInput(index, 'addressEurope', 'city')}
                    </>
                  ) : (
                    `${brand.addressEurope?.street || ''}, ${
                      brand.addressEurope?.postalcode || ''
                    } ${brand.addressEurope?.city || ''}`
                  )}
                </td>
                <td>
                  {editingIndex === index
                    ? renderInput(index, 'emailEurope')
                    : brand.emailEurope}
                </td>

                <td>
                  {editingIndex === index ? (
                    <button
                      className="btn btn-success"
                      onClick={() => handleSave(index)}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => setEditingIndex(index)}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default BrandsInfoScreen;
