import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from 'utils/utils';
import LoadingBox from './LoadingBox';

export default function AdminRoute() {
  const { isAdmin, loading } = useAuth();

  if (loading) {
    return <LoadingBox />;
  }

  return isAdmin ? <Outlet /> : <Navigate to="/admin/signin" />;
}
