import axios from 'axios';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

function QRCodeRedirectScreen() {
  const { search } = useLocation();

  const getRedirectUrl = (type) => {
    switch (type) {
      case 'facebook':
        return 'https://www.facebook.com/braunbaerchen.de';
      case 'instagram':
        return 'https://www.instagram.com/braunbaerchen.de';
      case 'shop':
        return 'https://braunbaerchen.de/shop/kleidung';
      default:
        return 'https://braunbaerchen.de/';
    }
  };

  useEffect(() => {
    const sp = new URLSearchParams(search);
    const source = sp.get('src');
    const target = sp.get('target');

    const getQRData = async () => {
      await axios.post('/api/redirect/qrcode', {
        target: target,
        source: source,
      });
    };
    getQRData().then(() => {
      const url = getRedirectUrl(target);
      window.location.replace(url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          BraunBärchen | BraunBärchen Online-Secondhand-Shop für Kinderbedarf
        </title>
      </Helmet>
    </div>
  );
}

export default QRCodeRedirectScreen;
