import { PropagateLoader } from 'react-spinners';

export default function LoadingBox(props) {
  const { margin } = props;
  return (
    <div className={`w-100 d-flex justify-content-center my-${margin ?? '5'}`}>
      <PropagateLoader
        color="#57c9b4"
        speedMultiplier={0.75}
        style={{ position: 'relative', top: '-7.5px' }}
      >
        <span className="visually-hidden">Loading...</span>
      </PropagateLoader>
    </div>
  );
}
