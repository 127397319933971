import { Col, Modal, Row, Stack } from 'react-bootstrap';
import { getCDNImage } from 'utils/utils';

export default function RemoveProductModal(props) {
  const { selectedProduct } = props;
  return (
    <Modal
      show={props.show}
      size="md"
      aria-labelledby="remove-product-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Artikel wirklich löschen?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={4} sm={4} md={3}>
            <img
              src={getCDNImage(selectedProduct.image ?? '')?.replace(
                '.png',
                '_lowres.png'
              )}
              alt={selectedProduct.name}
              className="img-fluid rounded img-thumbnail"
            />
          </Col>
          <Col xs={5} sm={5} md={7} lg={8}>
            <Stack gap={0}>
              <div
                className="fw-bold"
                style={{
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
              >
                {selectedProduct.category}
              </div>
              <div>{selectedProduct.brand}</div>
              <div style={{ minHeight: '1.5rem' }}>
                {/* {selectedProduct.description}*/}
              </div>
              <div className="text_cart__size">
                {selectedProduct.sizeHat &&
                selectedProduct.sizeHat.length > 0 ? (
                  <>
                    {selectedProduct.sizeHat.length >= 2 ? (
                      <div>
                        Hutgr.{` `}
                        {Math.min(...selectedProduct.sizeHat)} -{' '}
                        {Math.max(...selectedProduct.sizeHat)}
                      </div>
                    ) : (
                      <div>
                        Hutgr.{` `}
                        {selectedProduct.sizeHat[0]}
                      </div>
                    )}
                  </>
                ) : (
                  <>Gr. {selectedProduct.size}</>
                )}
              </div>
            </Stack>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Row className="w-100">
          <Col
            xs={{ span: 5, offset: 1 }}
            sm={{ span: 4, offset: 2 }}
            md={{ span: 4, offset: 2 }}
            className="d-flex justify-content-center"
          >
            <div
              className="btn_action w-100 no-mw"
              onClick={() => {
                props.removeItemHandler(selectedProduct);
                props.onHide();
              }}
            >
              Ja
            </div>
          </Col>
          <Col
            xs={5}
            sm={{ span: 4, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            className="d-flex justify-content-center"
          >
            <div className="btn_action w-100 no-mw" onClick={props.onHide}>
              Nein
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
