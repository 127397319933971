import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Button,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import useAuth, { isFreeShippingDay } from 'utils/utils';
import { ReactComponent as BagIcon } from 'assets/images/local_mall.svg';
import { ToastContainer } from 'react-toastify';
import MessageRotator from 'components/MessageRotator';
import { isBrowser } from 'react-device-detect';
import { useContext, useEffect, useState } from 'react';
import { Store } from 'Store';
import Sidebar from './Sidebar';
import CartSidebar from './CartSidebar';

const Header = () => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart } = state;
  const authState = useAuth();

  const signoutHandler = () => {
    try {
      ctxDispatch({ type: 'USER_SIGNOUT' });
      localStorage.removeItem('userInfo');
      localStorage.removeItem('shippingAddress');
      localStorage.removeItem('paymentMethod');
      window.location.href = '/admin/signin';
    } catch (error) {
      console.log(error);
    }
  };

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [cartSidebarIsOpen, setCartSidebarIsOpen] = useState(false);

  useEffect(() => {
    if (sidebarIsOpen || cartSidebarIsOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.touchAction = 'none';
      if (isBrowser) document.body.style.paddingRight = '17px';
    } else {
      document.body.style.overflowY = '';
      document.body.style.touchAction = '';
      if (isBrowser) document.body.style.paddingRight = '';
    }
  }, [sidebarIsOpen, cartSidebarIsOpen]);

  const AdminNav = () => {
    return (
      <Nav className="justify-content-end">
        {authState.isAdmin && (
          <NavDropdown title="A" id="adminDropdown" className="adminDropdown">
            <NavDropdown.Item href="/admin/dashboard">
              Übersicht
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/tracking">
              Benutzertracking
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/neuekleidung">
              Neue Kleidung
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/neuerschlafsack">
              Neuer Schlafsack
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/neuesaccessoires">
              Neues Accessoires
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/products">
              Artikelliste
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/prices">
              Preise und Factoren
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/orderhistory">
              Bestellhistorie
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/reports">Reports</NavDropdown.Item>
            <NavDropdown.Item href="/admin/sale">Sale</NavDropdown.Item>
            <NavDropdown.Item href="/admin/sellerlist">
              Verkäuferliste
            </NavDropdown.Item>
            <NavDropdown.Item href="/admin/brandsinfo">
              Marken-Info-Liste
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => signoutHandler()}>
              Ausloggen
            </NavDropdown.Item>
          </NavDropdown>
        )}
        <div
          onClick={() => {
            setSidebarIsOpen(false);
            setCartSidebarIsOpen(!cartSidebarIsOpen);
          }}
          className="nav-link pe-3 d-flex align-items-center"
          id="card"
          style={{ cursor: 'pointer' }}
        >
          <Badge
            pill
            bg="danger"
            className={`cart-badge ${
              cart.cartItems.length === 0 ? 'cart-badge-hidden' : ''
            }`}
          >
            {cart.cartItems.length}
          </Badge>

          <span className="d-block">
            <BagIcon />
          </span>
        </div>
      </Nav>
    );
  };

  const HeaderBrowser = () => {
    return (
      <Row className="w-100 mx-auto g-0 d-flex align-items-end">
        {window.innerWidth >= 1270 && (
          <div className="topbar text-center">
            {/* Kostenloser Versand ab 50 € Einkaufswert ~ */}
            {`~~~ ${
              isFreeShippingDay
                ? 'Heute kostenloser Versand'
                : 'Versandkosten 4,99 €'
            } deutschlandweit ~ Kostenlose
                        Abholung in Wipperfürth ~ Zweiwöchiges Rückgaberecht ~~~`}
          </div>
        )}
        <Col>
          <Row className="w-100 justify-content-start">
            <Col className="d-flex justify-content-start">
              <Nav
                className="justify-content-end pt-2 pb-3 ps-4 d-flex align-items-center"
                role="navigation"
                aria-label="Main menu"
              >
                <Button
                  variant="light"
                  onClick={() => {
                    setCartSidebarIsOpen(false);
                    setSidebarIsOpen(!sidebarIsOpen);
                  }}
                  size="sm"
                  color="#000000"
                  className="menuButton"
                  aria-haspopup="menu"
                  id="al"
                  aria-label="Sidebar"
                  aria-controls="main-menu"
                >
                  <FontAwesomeIcon icon="bars" />
                </Button>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Link to="/shop/kleidung" className="navbar_category">
                Kleidung
              </Link>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex justify-content-center">
          <Link to="/shop/schlafsack" className="navbar_category">
            Schlafsäcke
          </Link>
        </Col>
        <Col
          className="d-flex justify-content-center px-0 col-4"
          style={{ height: 111 }}
        >
          <LinkContainer to="/">
            <Navbar.Brand title="BraunBärchen" href="/" className="mx-0">
              <img
                height={100}
                src="/logoBB.svg"
                className="d-inline-block align-top"
                alt="BraunBärchen logo"
              />
            </Navbar.Brand>
          </LinkContainer>
        </Col>
        <Col className="d-flex justify-content-center">
          <Link to="/shop/accessoires" className="navbar_category">
            Accessoires
          </Link>
        </Col>
        <Col>
          <Row className="w-100 justify-content-end">
            <Col className="d-flex justify-content-end px-0 col-4">
              <AdminNav />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Link to="/mieten" className="navbar_category">
                Mieten
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const HeaderMobile = () => {
    return (
      <Row className="w-100 mx-auto g-0 d-flex align-items-center">
        <Col className="d-flex justify-content-start h-100 align-items-center ps-3 col-4">
          <Button
            variant="light"
            onClick={() => {
              setCartSidebarIsOpen(false);
              setSidebarIsOpen(!sidebarIsOpen);
            }}
            size="sm"
            color="#000000"
            className="menuButton"
          >
            <FontAwesomeIcon icon="bars" />
          </Button>
        </Col>
        <Col
          className="d-flex justify-content-center px-0 col-4"
          style={{ height: 71 }}
        >
          <LinkContainer to="/">
            <Navbar.Brand title="BraunBärchen" href="/" className="mx-0">
              <img
                height={60}
                src="/logoBB.svg"
                className="d-inline-block align-top"
                alt="BraunBärchen logo"
              />
            </Navbar.Brand>
          </LinkContainer>
        </Col>
        <Col className="d-flex justify-content-end px-0 col-4">
          <AdminNav />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Sidebar
        isMenuActive={sidebarIsOpen}
        onOverLayClick={() => {
          setSidebarIsOpen(!sidebarIsOpen);
        }}
      />
      <CartSidebar
        isMenuActive={cartSidebarIsOpen}
        onOverLayClick={() => {
          setCartSidebarIsOpen(!cartSidebarIsOpen);
        }}
      />
      <header>
        <ToastContainer position="bottom-center" limit={1} theme="light" />

        <div className={`navbar p-0 w-100`} expand="lg" style={{ top: `0px` }}>
          {window.innerWidth < 1270 && <MessageRotator />}
          {isBrowser ? <HeaderBrowser /> : <HeaderMobile />}
        </div>
      </header>
    </>
  );
};

export default Header;
