//import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
//import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import './components/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import { StoreProvide } from './Store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AuthProvider, initializeGA, sendToAnalytics } from 'utils/utils';
import QRCodeRedirectScreen from 'views/QRCode/QRCodeScreen';
import GreenWeekLandingPage from 'views/LandingPages/GreenWeekLandingPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/redirect/qrcode',
    element: <QRCodeRedirectScreen />,
  },
  {
    path: '/r/qr',
    element: <QRCodeRedirectScreen />,
  },
  { path: '/green-week', element: <GreenWeekLandingPage /> },
  {
    path: '/*',
    element: <App />,
  },
]);

initializeGA();

root.render(
  //<React.StrictMode>
  <StoreProvide>
    <HelmetProvider>
      <PayPalScriptProvider deferLoading={true}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </PayPalScriptProvider>
    </HelmetProvider>
  </StoreProvide>
  //</React.StrictMode>
);

reportWebVitals(sendToAnalytics);
