import { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './css/messagerouter.css'; // Für die CSS-Übergänge

const today = new Date();
const isFreeShippingDay =
  (today.getDate() === 31 && today.getMonth() === 9) || // 31.10.
  (today.getDate() === 1 && today.getMonth() === 10); // 1.11.

const messages = [
  // 'Kostenloser Versand ab 50 € Einkaufswert',
  `${
    isFreeShippingDay ? 'Heute kostenloser Versand' : 'Versandkosten 4,99 €'
  } deutschlandweit`,
  'Kostenlose Abholung in Wipperfürth',
  'Zweiwöchiges Rückgaberecht',
];

const MessageRotator = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="message-rotator">
      <TransitionGroup>
        <CSSTransition
          key={currentMessageIndex}
          timeout={1000}
          classNames={'slide'}
        >
          <div>~ {messages[currentMessageIndex]} ~</div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default MessageRotator;
