import 'components/css/sidebar.css';
import { Component } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import ReactRA from 'react-ga4';

class Sidebar extends Component {
  render() {
    const { isMenuActive, onOverLayClick } = this.props;

    const sideMenuClasses = classnames('side-menu side-menu-left', {
      'side-menu--active': isMenuActive,
      'side-menu-left--active': isMenuActive,
    });
    const sideMenuContentClasses = classnames(
      'side-menu__content side-menu-left__content',
      {
        'side-menu__content--active': isMenuActive,
        'side-menu-left__content--active': isMenuActive,
      }
    );

    return (
      <aside className={sideMenuClasses}>
        <div className={'side-menu__overlay'} onClick={onOverLayClick} />
        <div className={sideMenuContentClasses}>
          <div
            className="closeSideMenu d-flex justify-content-end"
            onClick={onOverLayClick}
          >
            <FontAwesomeIcon
              icon="rectangle-xmark"
              style={{ color: '#333' }}
              size="xl"
            />
          </div>
          <h2>Kaufen</h2>
          <ul>
            <li>
              <Link
                to="/shop/accessoires"
                onClick={() => {
                  onOverLayClick();
                  ReactRA.event('Öffne Shop - Accessoires');
                }}
              >
                <button
                  type="button"
                  className="side-menu-btn side-menu-btn--yellow"
                >
                  <span className="btn__txt">Accessoires</span>
                </button>
              </Link>
            </li>
            <li>
              <Link
                to="/shop/kleidung"
                onClick={() => {
                  onOverLayClick();
                  ReactRA.event('Öffne Shop - Kleidung');
                }}
              >
                <button
                  type="button"
                  className="side-menu-btn side-menu-btn--orange"
                >
                  <span className="btn__txt">Kleidung</span>
                </button>
              </Link>
            </li>
            <li>
              <Link
                to="/shop/schlafsack"
                onClick={() => {
                  onOverLayClick();
                  ReactRA.event('Öffne Shop - Schlafsäcke');
                }}
              >
                <button
                  type="button"
                  className="side-menu-btn side-menu-btn--green"
                >
                  <span className="btn__txt">Schlafsäcke</span>
                </button>
              </Link>
            </li>
          </ul>
          {/* 
          <h2>Mieten</h2>
          <ul>
            <li>
              <Link to="/?gender=male" onClick={onOverLayClick}>
              <button
                type="button"
                className="side-menu-btn side-menu-btn--red"
              >
                <span className="btn__txt">Kleidung</span>
                <span className="coming-soon">Coming soon</span>
              </button>
               </Link>
            </li>
            <li>
              <button
                type="button"
                className="side-menu-btn side-menu-btn--yellow"
              >
                <span className="btn__txt">Spielsachen & mehr</span>
                <span className="coming-soon">Coming soon</span>
              </button>
            </li>
          </ul>
          */}
          <h2>Informatives</h2>
          <ul>
            <li>
              <Link
                to="/ueber-uns"
                onClick={() => {
                  onOverLayClick();
                  ReactRA.event('Über uns');
                }}
              >
                <button
                  type="button"
                  className="side-menu-btn side-menu-btn--blue"
                >
                  <span className="btn__txt">Über uns</span>
                </button>
              </Link>
            </li>
            {!isBrowser && (
              <li>
                <Link
                  to="/webapp"
                  onClick={() => {
                    onOverLayClick();
                    ReactRA.event('BraunBaerchen als WebApp');
                  }}
                >
                  <button
                    type="button"
                    className="side-menu-btn side-menu-btn--red"
                  >
                    <span className="btn__txt">BraunBärchen als Web-App</span>
                  </button>
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/verkaufen"
                onClick={() => {
                  onOverLayClick();
                  ReactRA.event('Verkäufer werden');
                }}
              >
                <button
                  type="button"
                  className="side-menu-btn side-menu-btn--purple"
                >
                  <span className="btn__txt">Kleidung verkaufen</span>
                </button>
              </Link>
            </li>
            <li>
              <Link
                to="/abholstation"
                onClick={() => {
                  onOverLayClick();
                  ReactRA.event('Abholstation');
                }}
              >
                <button
                  type="button"
                  className="side-menu-btn side-menu-btn--green"
                >
                  <span className="btn__txt">Abholstation</span>
                </button>
              </Link>
            </li>
            <li>
              <Link to="/faqs" onClick={onOverLayClick}>
                <button
                  type="button"
                  className="side-menu-btn side-menu-btn--yellow"
                >
                  <span className="btn__txt">FAQs</span>
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
