import { Col, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

const NavToShops = () => {
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      {inView ? (
        <>
          <Row className="mb-3 g-0 w-100">
            <Col className="d-flex justify-content-center headline_highlights position-relative font-poppins  text-center">
              <h1>Hier geht's zum Shop</h1>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col
              className="d-flex justify-content-center"
              style={{ flexFlow: 'row wrap' }}
            >
              <div
                className="btn_action mx-2 mb-3"
                onClick={() => navigate('/shop/accessoires')}
              >
                Accessoires
              </div>
              <div
                className="btn_action mx-2 mb-3"
                onClick={() => navigate('/shop/kleidung')}
              >
                Kleidung
              </div>
              <div
                className="btn_action mx-2 mb-3"
                onClick={() => navigate('/shop/schlafsack')}
              >
                Schlafsäcke
              </div>

              {/*
                      <div
                        className="btn_action mx-2 mb-3"
                        onClick={() => navigate('/shop/tonies')}
                      >
                        Tonies
                      </div>
                      */}
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
};

export default NavToShops;
