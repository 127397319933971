import '../../components/css/GreenWeekLandingPage.css'; // Für das Styling
import Footer from 'components/Layout/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PiTreeDuotone, PiPlantFill } from 'react-icons/pi';
import { isBrowser } from 'react-device-detect';
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { Store } from 'Store';

const GreenWeekLandingPage = () => {
  const { state } = useContext(Store);
  const {
    id,
    cart: { cartItems },
  } = state;

  const navigate = useNavigate();

  useEffect(() => {
    const userAction = async () => {
      await axios.get('/api/users/opensite', {
        headers: {
          userid: id,
          cart: cartItems.map((c) => c._id),
          page: 'Landingpage - Green Week',
        },
      });
    };
    userAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        />
      </Helmet>
      <header
        className="green-week-header-container p-2 w-100 d-flex justify-content-center"
        expand="lg"
      >
        <img
          height={isBrowser ? 50 : 40}
          src="/logo_mit_text.svg"
          className="d-inline-block align-top"
          alt="BraunBärchen logo"
        />
      </header>
      <div className="green-week-container">
        <header className="green-week-header d-flex flex-column justify-content-evenly">
          <div className="header-overlay mx-3">
            <h1 className="fade-in">
              Unsere Green Week: Gemeinsam für eine grünere Zukunft{' '}
              <PiPlantFill fill="#2c6e49" />
            </h1>
          </div>
          <div
            className="btn_action"
            style={{ borderRadius: '0.75rem' }}
            onClick={() => navigate('/shop/kleidung')}
          >
            Hier geht's zum Shop
          </div>
        </header>
        <section className="green-week-content pt-0">
          <div className="green-week-text">
            <p>
              Willkommen zu unserer Green Week, bei der Nachhaltigkeit im
              Mittelpunkt steht! Statt der üblichen Rabattschlachten setzen wir
              ein Zeichen für die Umwelt: Für jede Bestellung, die während der
              Green Week eingeht, pflanzen wir einen Baum – in Kooperation mit
              unseren Partnern von{' '}
              <Link
                to="https://www.greenadz.de/referenzkunden-und-baumpaten"
                style={{ textDecoration: 'none' }}
              >
                Green Adz
              </Link>
              . <PiTreeDuotone fill={'#2c6e49'} />
            </p>
            <h2 className="text-center">Wie funktioniert das?</h2>
            <div className="how-it-works">
              <div className="step delay-1">
                <h3>1. Bestellung</h3>
                <p>Du bestellst wie gewohnt: Jede Bestellung zählt.</p>
              </div>
              <i
                className={`fas fa-arrow-${isBrowser ? 'right' : 'down'} arrow`}
              />
              <div className="step delay-2">
                <h3>2. Baum pflanzen</h3>
                <p>
                  Für jede Bestellung wird ein Baum in zertifizierten
                  Aufforstungsprojekten gepflanzt.
                </p>
              </div>
              <i
                className={`fas fa-arrow-${isBrowser ? 'right' : 'down'} arrow`}
              />
              <div className="step delay-3">
                <h3>3. Unterschied machen</h3>
                <p>
                  Dein Einkauf unterstützt die Wiederaufforstung und trägt aktiv
                  zum Klimaschutz bei.
                </p>
              </div>
            </div>
            <h2 className="text-center">Warum Green Week?</h2>
            <p>
              Wir möchten nicht nur hochwertige Produkte anbieten, sondern auch
              Verantwortung übernehmen. Mit der Green Week leisten wir gemeinsam
              einen Beitrag, um der Umwelt etwas zurückzugeben – und das ohne
              Verzicht oder Mehraufwand für dich.
            </p>
            <p>
              <strong>
                Die Green Week läuft vom 23. November bis 29. November.
              </strong>{' '}
              Jeder Baum zählt – lass uns gemeinsam wachsen und eine nachhaltige
              Zukunft gestalten!
            </p>
            <p>Vielen Dank, dass du Teil der Veränderung bist.</p>
          </div>
          <div className="d-flex justify-content-center py-3">
            <div className="green-week-footer-image"></div>
          </div>
          <div className="d-flex flex-column justify-content-center text-center py-5">
            <p>Jetzt mit deiner Bestellung einen Baum pflanzen!</p>
            <div className="d-flex justify-content-center">
              <div
                className="btn_action"
                style={{ borderRadius: '0.75rem' }}
                onClick={() => navigate('/shop/kleidung')}
              >
                Hier geht's zum Shop
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default GreenWeekLandingPage;
