import { Store } from 'Store';
import axios from 'axios';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { couponType } from './types';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export const initializeGA = () => {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GOOGLE_ADS,
      gaOptions: {
        name: 'google_ads',
      },
    },
    {
      trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS,
      gaOptions: {
        name: 'google_analytics',
      },
    },
  ]);
};

export const sendEvent = (eventName, params) => {
  const trackers = [
    window?.CCM?.acceptedEmbeddings?.some(
      (e) => e.name === 'Google Analytics 4'
    ) && 'google_analytics',
    window?.CCM?.acceptedEmbeddings?.some(
      (e) => e.name === 'Google Ads Conversion Tracking'
    ) && 'google_ads',
  ].filter(Boolean);

  if (trackers.length === 0) {
    // console.warn(
    //   `No trackers available for event "${eventName}". Ensure consent has been given for Google Analytics or Ads.`
    // );
    return;
  }

  try {
    ReactGA.event(eventName, params, trackers);

    // console.log(
    //   `Event "${eventName}" sent with params:`,
    //   params,
    //   'to trackers:',
    //   trackers
    // );
  } catch (error) {
    // console.error(`Failed to send event "${eventName}":`, error);
  }
};

export const loadCookiesDefault = () => {
  ReactGA.gtag(
    'consent',
    'default',
    {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    },
    ['google_ads', 'google_analytics']
  );
};

export const cookiesAccepted = ({ cookie, ctxDispatch }) => {
  // console.log('accepted: ' + cookie);
  switch (cookie) {
    case 'Google Analytics 4':
      ReactGA.gtag(
        'consent',
        'update',
        {
          analytics_storage: 'granted',
        },
        ['google_analytics']
      );
      break;
    case 'Google Ads Conversion Tracking':
      ReactGA.gtag(
        'consent',
        'update',
        {
          ad_storage: 'granted',
          ad_personalization: 'granted',
        },
        ['google_ads']
      );
      break;
    case 'Google Maps':
      ctxDispatch({
        type: 'UPDATE_GOOGLE_MAPS',
        payload: true,
      });
      break;
    default:
      break;
  }
};

export const cookiesDenied = ({ cookie, ctxDispatch }) => {
  // console.log('denied: ' + cookie);
  switch (cookie) {
    case 'Google Analytics 4':
      ReactGA.gtag(
        'consent',
        'update',
        {
          analytics_storage: 'denied',
        },
        ['google_analytics']
      );
      break;
    case 'Google Ads Conversion Tracking':
      ReactGA.gtag(
        'consent',
        'update',
        {
          ad_storage: 'denied',
          ad_personalization: 'denied',
        },
        ['google_ads']
      );
      break;
    case 'Google Maps':
      ctxDispatch({
        type: 'UPDATE_GOOGLE_MAPS',
        payload: false,
      });
      break;
    default:
      break;
  }
};

export const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    if (
      window.CCM?.acceptedEmbeddings?.some(
        (e) => e.name === 'Google Analytics 4'
      )
    ) {
      ReactGA.send(
        { hitType: 'pageview', page: location.pathname + location.search },
        ['google_analytics']
      );
    }
    if (
      window.CCM?.acceptedEmbeddings?.some(
        (e) => e.name === 'Google Ads Conversion Tracking'
      )
    ) {
      ReactGA.send(
        { hitType: 'pageview', page: location.pathname + location.search },
        ['google_ads']
      );
    }
  }, [location]);
};

export const sendToAnalytics = ({ name, delta, id }) => {
  ReactGA.gtag(
    'event',
    name,
    {
      event_category: 'Web Vitals',
      event_action: name,
      value: Math.round(name === 'CLS' ? delta * 1000 : delta), // CLS benötigt eine spezielle Behandlung
      event_label: id, // Optionaler Bezeichner für den Event
      non_interaction: true, // Event ist nicht durch den Nutzer direkt ausgelöst
    },
    ['google_analytics']
  );
};

export const getError = (error) => {
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};

export const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [appState, setAppState] = useState({ isAdmin: false, loading: true });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const authUser = async () => {
      if (userInfo && userInfo.token) {
        setAppState({
          ...appState,
          loading: true,
        });
        axios
          .get('/api/users/auth', {
            headers: {
              Authorization: `Baerer ${userInfo.token}`,
            },
          })
          .then(() => {
            setAppState({
              isAdmin: true,
              loading: false,
            });
          })
          .catch(() => {
            setAppState({
              isAdmin: false,
              loading: false,
            });
          });
      } else {
        setAppState({
          isAdmin: false,
          loading: false,
        });
      }
    };
    authUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <AuthContext.Provider value={appState}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const state = useContext(AuthContext);
  if (state === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return { ...state };
};

export default useAuth;

export function lazyLoadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.media = 'print';
  link.onload = function () {
    this.media = 'all';
  };
  document.head.appendChild(link);
}

export function getSizeText(p) {
  if (p.sizeHat && p.sizeHat.length > 0) {
    if (p.sizeHat.length >= 2) {
      return `Hut-${Math.min(...p.sizeHat)}-${Math.max(...p.sizeHat)}`;
    } else {
      return `Hut-${p.sizeHat[0]}`;
    }
  } else {
    return `${p.size}`;
  }
}

export function useCurrentHeight() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { lastHeight } = state;

  const wrapper = document.getElementById('wrapper');

  const setHeight = useCallback(() => {
    ctxDispatch({
      type: 'CONTAINER_HEIGHT',
      payload: wrapper.offsetHeight,
    });
  }, [ctxDispatch, wrapper.offsetHeight]);

  return [lastHeight, setHeight];
}

export function getCDNImage(path) {
  try {
    const cdnPath = path.replace(
      'braunbaerchenproductimages.s3.eu-central-1.amazonaws.com',
      'cdn.braunbaerchen.de'
    );
    return cdnPath;
  } catch (error) {
    console.log(path);
    return '';
  }
}

export async function checkCartHandler(props) {
  const { dispatch, ctxDispatch, cartItems, reserved, id } = props;

  dispatch({ type: 'REQUEST' });

  let success = true;

  const { data } = await axios.get(`/api/products/checkcart`, {
    params: {
      productids: cartItems.map((p) => p._id),
    },
  });
  const totalPrice = getTotalProductsPrice(
    data.products
  ).productsTotalWithDiscount;
  // round2(
  //   data.products.reduce(
  //     (a, c) =>
  //       c.sale
  //         ? a + Math.round(c.price * (1 - c.discount / 100) * 1e2) / 1e2
  //         : a + c.price,
  //     0
  //   )
  // );
  ctxDispatch({ type: 'UPDATE_TOTALPRICE', payload: totalPrice });

  if (data.soldProducts.length > 0) {
    success = false;
    data.soldProducts.forEach((item) => {
      let removedProduct = document.getElementById(item._id);
      removedProduct.style.opacity = '0';
    });
  }

  if (data.notFound.length > 0) {
    success = false;
    data.notFound.forEach((id) => {
      let removedProduct = document.getElementById(id);
      removedProduct.style.opacity = '0';
    });
  }

  if (data.products.length > 0) {
    data.products.forEach((item) => {
      let cartProduct = cartItems.find((p) => p._id === item._id);
      if (
        item.price !== cartProduct.price ||
        item.discount !== cartProduct.discount ||
        item.sale !== cartProduct.sale
      ) {
        success = false;
      } else if (
        new Date(item.reservedUntil) > new Date() &&
        !reserved.IDs.some((p) => p === item._id) &&
        item.reservedBy !== id
      ) {
        success = false;
        let removedProduct = document.getElementById(item._id);
        removedProduct.style.opacity = '0';
      } else if (item.sale !== cartProduct.sale) {
        success = false;
      }
    });
  }

  if (!success) {
    toast.info(
      <div className="d-flex">
        <div>{`Die Artikel in deinem Warenkorb wurden aktualisiert.`}</div>
      </div>,
      { draggable: false }
    );
    setTimeout(() => {
      ctxDispatch({
        type: 'CART_UPDATE_ITEMS',
        payload: { data },
      });
    }, 2000);
    dispatch({ type: 'SUCCESS' });
    return false;
  } else {
    dispatch({ type: 'SUCCESS' });
    return true;
  }
}

export const getProductPrices = (product, coupon = {}) => {
  const withDiscount = round2(
    product.sale ? product.price * (1 - product.discount / 100) : product.price
  );

  const withCoupon =
    coupon && coupon.discountType === couponType.Percentage
      ? round2((withDiscount * (100 - coupon.discountAmount)) / 100)
      : withDiscount;

  return { price: product.price, withDiscount, withCoupon };
};

export const getTotalProductsPrice = (products, coupon = {}) => {
  const productsTotalWithDiscount = round2(
    products.reduce(
      (a, product) => a + getProductPrices(product, coupon).withDiscount,
      0
    )
  );

  const productsTotalWithCoupon = round2(
    products.reduce(
      (a, product) => a + getProductPrices(product, coupon).withCoupon,
      0
    )
  );

  const productsTotalWithoutDiscount = round2(
    products.reduce((a, product) => a + product.price, 0)
  );
  const hasSale = products.some((p) => p.sale);

  return {
    productsTotalWithoutDiscount,
    productsTotalWithDiscount,
    productsTotalWithCoupon,
    hasSale,
  };
};

export const getTotalOrderPrice = (products, deliveryCosts, coupon) => {
  const {
    productsTotalWithoutDiscount,
    productsTotalWithDiscount,
    productsTotalWithCoupon,
    hasSale,
  } = getTotalProductsPrice(products, coupon);

  const totalPriceWithoutDiscount = round2(
    productsTotalWithCoupon + deliveryCosts
  );
  let couponDiscount = 0;
  if (coupon) {
    switch (coupon.discountType) {
      case couponType.Percentage:
        couponDiscount = round2(
          productsTotalWithCoupon - productsTotalWithDiscount
        );
        return {
          productsTotalWithoutDiscount,
          productsTotalWithDiscount,
          hasSale,
          couponDiscount,
          totalPrice: totalPriceWithoutDiscount,
        };
      case couponType.Fixed:
        couponDiscount = -coupon.discountAmount;
        return {
          productsTotalWithoutDiscount,
          productsTotalWithDiscount,
          hasSale,
          couponDiscount,
          totalPrice: round2(totalPriceWithoutDiscount + couponDiscount),
        };
      case couponType.FreeDelivery:
        couponDiscount = -deliveryCosts;
        return {
          productsTotalWithoutDiscount,
          productsTotalWithDiscount,
          hasSale,
          couponDiscount,
          totalPrice: round2(totalPriceWithoutDiscount + couponDiscount),
        };
      default:
        return {
          productsTotalWithoutDiscount,
          productsTotalWithDiscount,
          hasSale,
          couponDiscount: 0,
          totalPrice: totalPriceWithoutDiscount,
        };
    }
  } else {
    return {
      productsTotalWithoutDiscount,
      productsTotalWithDiscount,
      hasSale,
      couponDiscount,
      totalPrice: totalPriceWithoutDiscount,
    };
  }
};

const today = new Date();
export const isFreeShippingDay =
  (today.getDate() === 31 && today.getMonth() === 9) || // 31.10.
  (today.getDate() === 1 && today.getMonth() === 10); // 1.11.
